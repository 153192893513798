import React from "react";
import { Link } from "react-router-dom";

import Slider from "react-slick";
import 'aos/dist/aos.css';


//Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const NavCarousel = () => {
    const settingsLg = {
        arrows: false,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        speed: 2000,
        infinte: true,
        pauseOnHover: false,
        autoplaySpeed: 6000,
        cssEase: "linear"
    }

    const settingsMd = {
        arrows: false,
        autoplay: true,
        centerPadding: "300px",
        slidesToScroll: 1,
        slidesToShow: 1,
        fade: true,
        speed: 2000,
        autoplaySpeed: 6000,
        infinite: true
    }

    const settingsSm = {
        arrows: false,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 2000,
        autoplaySpeed: 6000,
        infinte: true,
        fade: true
    }

    return (
        <>
            {/* Small Screens */}
            <div className="md:hidden mt-16 ">
                <Slider {...settingsSm}>
                    <div className="relative w-full h-60 focus:outline-none z-0">
                        <img src={require('../../Assets/SlideShow/HighRise1.JPG')} alt="image" className="h-full w-full object-cover"/>
                        <div className="absolute top-0 w-full h-full  bg-black bg-opacity-25 p-3">
                            <div className="absolute bottom-[10%] left-[2%]">
                                <h1 className="text-2xl text-white font-semibold w-[70%] font-title">Design of High-Rise Buildings</h1>
                                <p className="text-[#EBE4FF] text-sm mt-[5px] leading-4">Experience the future of urban living with our cutting-edge high-rise designs, where luxury meets innovation in every detail</p>
                                <div className="relative flex gap-3 mt-[5px]">
                                    <div>
                                        <Link to="">
                                            <button className="bg-[#003F75] text-white px-6 py-2 rounded-full text-sm">Explore</button>
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to="/project">
                                            <button className="bg-white text-[#003F75] px-6 py-2 rounded-full text-sm cursor-pointer">Our Work</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative w-full h-60 focus:outline-none">
                        <img src={require('../../Assets/SlideShow/IMG20220.jpg')} alt="Construction" className="h-full w-full" />
                        <div className="absolute top-0 w-full h-full  bg-black bg-opacity-25 p-3">
                            <div className="absolute bottom-[10%] left-[2%]">
                                <h1 className="text-xl text-white font-semibold w-[99%] font-title">Residential Design & Supervision of Residential Units</h1>
                                <p className="text-[#EBE4FF] text-sm mt-[5px] leading-4">Transform your dream home with our expert Residential Design & Supervision, blending visionary design and meticulous craftsmanship to create spaces you'll love.</p>
                                <div className="relative flex gap-3 mt-[5px]">
                                    <div>
                                        <Link to="">
                                            <button className="bg-[#003F75] text-white px-6 py-2 rounded-full text-sm">Explore</button>
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to="/project">
                                            <button className="bg-white text-[#003F75] px-6 py-2 rounded-full text-sm cursor-pointer">Our Work</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative w-full h-60 focus:outline-none">
                        <img src={require('../../Assets/SlideShow/IMG_20180.jpg')} alt="Constrction" className="h-full w-full" />
                        <div className="absolute top-0 w-full h-full  bg-black bg-opacity-25 p-3">
                            <div className="absolute bottom-[10%] left-[2%]">
                                <h1 className="text-2xl text-white font-semibold w-[70%] font-title">Project Management</h1>
                                <p className="text-[#EBE4FF] text-sm mt-[5px] leading-4">Transform your construction vision into reality with our expert Project Management services, ensuring on-time, on-budget, and top-quality delivery for every project!</p>
                                <div className="relative flex gap-3 mt-[5px]">
                                    <div>
                                        <Link to="">
                                            <button className="bg-[#003F75] text-white px-6 py-2 rounded-full text-sm">Explore</button>
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to="/project">
                                            <button className="bg-white text-[#003F75] px-6 py-2 rounded-full text-sm cursor-pointer">Our Work</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative w-full h-60 focus:outline-none">
                        <img src={require('../../Assets/SlideShow/RichmondSuites1.jpg')} alt="Construction" className="h-full w-full " />
                        <div className="absolute top-0 w-full h-full  bg-black bg-opacity-25 p-3">
                            <div className="absolute bottom-[10%] left-[2%]">
                                <h1 className="text-2xl text-white font-semibold w-[99%] font-title">Reinforced Concrete Design</h1>
                                <p className="text-[#EBE4FF] text-sm mt-[5px] leading-4">Turn your vision into reality with our advanced Reinforced Concrete Design services, ensuring unmatched strength, durability, and precision—let's build the future together!</p>
                                <div className="relative flex gap-3 mt-[5px]">
                                    <div>
                                        <Link to="">
                                            <button className="bg-[#003F75] text-white px-6 py-2 rounded-full text-sm">Explore</button>
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to="/project">
                                            <button className="bg-white text-[#003F75] px-6 py-2 rounded-full text-sm cursor-pointer">Our Work</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>

            {/* Medium Screen */}
            <div className="hidden lg:hidden md:block md:mt-[90px]">
                <Slider {...settingsMd}>
                    <div className="relative w-full h-[450px]  focus:outline-none">
                        <img src={require('../../Assets/SlideShow/HighRise1.JPG')} alt="Construction" 
                        className="h-full w-full object-cover"/>
                        <div className="absolute top-0 w-full h-full  bg-black bg-opacity-25 p-3">
                            <div className="absolute bottom-[15%] left-[2%]">
                                <h1 className="text-5xl text-white font-semibold w-[70%] font-title">Design of High-Rise Buildings</h1>
                                <p className="text-[#EBE4FF] text-sm mt-[5px]">Experience the future of urban living with our cutting-edge high-rise designs, where luxury meets innovation in every detail</p>
                                <div className="relative flex gap-3 mt-[5px]">
                                    <div>
                                        <Link to="">
                                            <button className="bg-[#003F75] text-white px-6 py-2 rounded-full text-sm">Explore</button>
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to="/project">
                                            <button className="bg-white text-[#003F75] px-6 py-2 rounded-full text-sm cursor-pointer">Our Work</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative w-full h-[450px]  focus:outline-none">
                        <img src={require('../../Assets/SlideShow/IMG20220.jpg')} alt="Construction" className="h-full w-full"/>
                        <div className="absolute top-0 w-full h-full  bg-black bg-opacity-25 p-3">
                            <div className="absolute bottom-[15%] left-[2%]">
                                <h1 className="text-5xl text-white font-semibold w-[98%] font-title">Residential Design & Supervision of Residential Units</h1>
                                <p className="text-[#EBE4FF] text-sm mt-[5px]">Transform your dream home into reality with our expert Residential Design & Supervision services, where visionary design meets meticulous craftsmanship to create spaces you'll love living in.</p>
                                <div className="relative flex gap-3 mt-[5px]">
                                    <div>
                                        <Link to="">
                                            <button className="bg-[#003F75] text-white px-6 py-2 rounded-full text-sm">Explore</button>
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to="/project">
                                            <button className="bg-white text-[#003F75] px-6 py-2 rounded-full text-sm cursor-pointer">Our Work</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative w-full h-[450px]  focus:outline-none">
                        <img src={require('../../Assets/SlideShow/IMG_20180.jpg')} alt="Construction" className="h-full w-full"/>
                        <div className="absolute top-0 w-full h-full  bg-black bg-opacity-25 p-3">
                            <div className="absolute bottom-[15%] left-[2%]">
                                <h1 className="text-5xl text-white font-semibold w-[70%] font-title">Project Management</h1>
                                <p className="text-[#EBE4FF] text-sm mt-[5px]">Transform your construction vision into reality with our expert Project Management services, ensuring on-time, on-budget, and top-quality delivery for every project!</p>
                                <div className="relative flex gap-3 mt-[5px]">
                                    <div>
                                        <Link to="">
                                            <button className="bg-[#003F75] text-white px-6 py-2 rounded-full text-sm">Explore</button>
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to="/project">
                                            <button className="bg-white text-[#003F75] px-6 py-2 rounded-full text-sm cursor-pointer">Our Work</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative w-full h-[450px]  focus:outline-none">
                        <img src={require('../../Assets/SlideShow/RichmondSuites1.jpg')}  alt="Construction" className="h-full w-full" />
                        <div className="absolute top-0 w-full h-full  bg-black bg-opacity-25 p-3">
                            <div className="absolute bottom-[15%] left-[2%]">
                                <h1 className="text-5xl text-white font-semibold w-[80%] font-title">Reinforced Concrete Design</h1>
                                <p className="text-[#EBE4FF] text-sm mt-[5px]">Transform your construction vision into a reality with our cutting-edge Reinforced Concrete Design services, engineered for unparalleled strength, durability, and precision—let's build the future together!</p>
                                <div className="relative flex gap-3 mt-[5px]">
                                    <div>
                                        <Link to="">
                                            <button className="bg-[#003F75] text-white px-6 py-2 rounded-full text-sm">Explore</button>
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to="/project">
                                            <button className="bg-white text-[#003F75] px-6 py-2 rounded-full text-sm cursor-pointer">Our Work</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>

            {/* Large Screen */}
            <div className="hidden lg:block">
                <Slider {...settingsLg}>
                    <div className="relative w-full h-screen focus:outline-none">
                        <img src={require('../../Assets/SlideShow/HighRise1.JPG')} alt="carousel" className="h-full w-full  object-cover"/>
                        <div className="absolute top-0 w-full h-full  bg-black bg-opacity-25 p-3">
                            <div className="absolute bottom-[25%] left-[5%]">
                                <h1 className="text-7xl text-white font-semibold w-[70%] font-title">Design of High-Rise Buildings</h1>
                                <p className="text-[#EBE4FF] text-sm mt-[20px]">Experience the future of urban living with our cutting-edge high-rise designs, where luxury meets innovation in every detail</p>
                                <div className="relative flex gap-3 mt-[20px]">
                                    <div>
                                        <Link to="/project">
                                            <button className="bg-[#003F75] text-white px-6 py-2 rounded-full text-lg">Explore</button>
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to="/project">
                                            <button className="bg-white text-[#003F75] px-6 py-2 rounded-full text-lg cursor-pointer">Our Work</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative w-full h-screen focus:outline-none font-title">
                        <img src={require('../../Assets/SlideShow/IMG20220.jpg')} alt="carousel" className="h-full w-full object-cover" />
                        <div className="absolute top-0 w-full h-full  bg-black bg-opacity-25 p-2">
                            <div className="absolute bottom-[25%] left-[5%]">
                                <h1 className="text-6xl text-white font-semibold w-[70%]">Residential Design & Supervision of Residential Units</h1>
                                <p className="text-[#EBE4FF] text-sm mt-[20px] w-[70%]">Transform your dream home into reality with our expert Residential Design & Supervision services, where visionary design meets meticulous craftsmanship to create spaces you'll love living in.</p>
                                <div className="relative flex gap-3 mt-[20px]">
                                    <div>
                                        <Link to="/project">
                                            <button className="bg-[#003F75] text-white px-6 py-2 rounded-full text-lg">Explore</button>
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to="/project">
                                            <button className="bg-white text-[#003F75] px-6 py-2 rounded-full text-lg cursor-pointer">Our Work</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative w-full h-screen focus:outline-none">
                        <img src={require('../../Assets/SlideShow/IMG_20180.jpg')} alt="carousel" className="h-full w-full object-cover" />
                        <div className="absolute top-0 w-full h-full  bg-black bg-opacity-25 p-2">
                            <div className="absolute bottom-[25%] left-[5%]">
                                <h1 className="text-6xl text-white font-semibold w-[70%]">Project Management</h1>
                                <p className="text-[#EBE4FF] text-sm mt-[20px] w-[70%]">Transform your construction vision into reality with our expert Project Management services, ensuring on-time, on-budget, and top-quality delivery for every project!</p>
                                <div className="relative flex gap-3 mt-[20px]">
                                    <div>
                                        <Link to="/project">
                                            <button className="bg-[#003F75] text-white px-6 py-2 rounded-full text-lg">Explore</button>
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to="/project">
                                            <button className="bg-white text-[#003F75] px-6 py-2 rounded-full text-lg cursor-pointer">Our Work</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative w-full h-screen focus:outline-none">
                        <img src={require('../../Assets/SlideShow/RichmondSuites1.jpg')} alt="carousel" className="h-full w-full object-cover" />
                        <div className="absolute top-0 w-full h-full  bg-black bg-opacity-25 p-2">
                            <div className="absolute bottom-[25%] left-[5%]">
                                <h1 className="text-6xl text-white font-semibold">Reinforced Concrete Design</h1>
                                <p className="text-[#EBE4FF] text-sm mt-[20px] w-[70%]">Transform your construction vision into a reality with our cutting-edge Reinforced Concrete Design services, engineered for unparalleled strength, durability, and precision—let's build the future together!</p>
                                <div className="relative flex gap-3 mt-[20px]">
                                    <div>
                                        <Link to="/project">
                                            <button className="bg-[#003F75] text-white px-6 py-2 rounded-full text-lg">Explore</button>
                                        </Link>
                                    </div>
                                    <div>
                                        <Link to="/project">
                                            <button className="bg-white text-[#003F75] px-6 py-2 rounded-full text-lg cursor-pointer">Our Work</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </>
    );
}

export default NavCarousel;