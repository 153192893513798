import React from "react";
import Slider from "react-slick";

//Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const KeyWestCarousel = () => {
    const Settings = {
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 6000,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1
    }


    return (
        <>
            <div className="w-[90%] lg:w-3/4 mx-auto">
                <Slider {...Settings}>
                    <div className="w-full h-[230px] md:h-[400px] lg:h-[500px] focus:outline-none">
                        <img src={require('../../Assets/Projects/KEY WEST APARTMENTS/DSC06699.JPG')} alt="Key West Aprtment image" className="w-full h-full object-cover object-top" />
                    </div>
                    <div className="w-full h-[230px] md:h-[400px] lg:h-[500px] focus:outline-none">
                        <img src={require('../../Assets/Projects/KEY WEST APARTMENTS/DSC06700.JPG')} alt="Key West Aprtment image" className="w-full h-full object-cover object-top" />
                    </div>
                </Slider>
            </div>
        </>
    );
}

export default KeyWestCarousel;