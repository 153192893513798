import React from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet-async";


//Importing Icons
import { MdArchitecture } from "react-icons/md";
import { FaChalkboardTeacher, FaBuilding, FaSuitcase } from "react-icons/fa";
import { BsPrinterFill } from "react-icons/bs";
import { GiCargoCrane, GiSteelClaws } from "react-icons/gi";

//Compnents
import NavCarousel from "../components/carousel/carousel";


//Hompage Layout
const HomePage = () => {
    return (
        <>
            <Helmet>
                <title>Home - Finite Element Designs Limited</title>
                <meta 
                    name ="description"
                    content="Finite Element Designs Limited is a registered company in Kenya offering services for civil engineering and structural engineering works and services with the highest integrity, knowledge, and experience level."
                />
                <meta name="google-site-verification" content="1L0XEHtPG4pNfFWZTGugshQhnFMDPtbTBBSFyFO8K_0" />
            </Helmet>
            <html className="font-body scroll-smooth">
                <NavCarousel />
                <body >
                    {/* About Us */}
                    <section className="w-[90%] md:w-full lg:w-[90%] mx-auto my-[25px] md:flex justify-center gap-3  lg:h-[88vh]">
                        <div className="basis-[50%] lg:basis-[55%]">
                            <h3 className="text-[#BFB028] text-sm md:text-md md:font-medium">WELCOME TO:</h3>
                            <h1 className="text-2xl md:text-4xl font-medium text-[#003F75] mb-[10px] animate-text bg-gradient-to-r from-teal-500 via-purple-500 to-orange-500 bg-clip-text text-transparent">Finite Element Designs Limited</h1>
                            <div className="space-y-[10px] font-light">
                                <p>Finite Element Designs Limited, established in January 2015, represents a legacy of excellence in civil and structural engineering. The company has flourished under the leadership of Managing Director Eng. Phares Orina Atai, a structural engineer with over 18 years of postgraduate experience. He is a corporate member of the Institution of Engineers of Kenya and a professional practicing engineer registered with the Engineers Board of Kenya (EBK).</p>
                                <p>Since its inception, Finite Element Designs Limited has been a trailblazer in pioneering structural design methodologies, embracing cutting-edge technologies such as Prokon software and Finite Element numerical methods to deliver efficient and accurate engineering solutions.</p>
                                <p>The company's diverse portfolio includes civil engineering, structural design, road design, engineering consultancy, project management, and design-build services across various sectors. Our projects range from residential and commercial buildings to industrial structures and infrastructure development. One notable project is the Rugsan Mall in Mogadishu, completed in 2014, where Finite Element Designs Limited served as the structural engineer and project manager, overseeing all aspects of the project until its successful completion.</p>
                            </div>
                            <div className="mt-[15px] flex gap-3">
                                <Link to="/about">
                                    <button className="text-white text-lg md:text-xl md:font-semibold bg-[#003F75] rounded-md px-3 py-2 hover:bg-white hover:text-[#003F75] hover:border hover:border-[#003F75] transition-colors duration-300 ease-in-out">Learn More +</button>
                                </Link>
                                <Link to="/contact">
                                    <button className="text-[#003F75] text-lg md:text-xl md:font-semibold bg-white rounded-md px-3 py-2 hover:bg-[#003F75] hover:text-white border border-[#003F75] transition-colors duration-300 ease-in-out">Contact Us</button>
                                </Link>
                            </div>
                        </div>
                        <div className="md:basis-[45%] mt-[10px] md:mt-0 lg:basis-[33%] md:h-[60%] lg:h-full">
                            <img src={require('../Assets/Officials/IMG-20240105-WA0002.jpg')} alt="Founder" className="w-full h-full" />
                        </div>
                    </section>

                    {/* Our Services */}
                    <section id="services" className="w-full font-body pb-5 bg-[#F5F8FD]"    >
                        <div className="text-center w-[87%] mx-auto py-3  md:py-[25px]">
                            <h3 className="text-[#F4EB13] text-lg">SERVICES</h3>
                            <h1 className="text-2xl md:text-3xl lg:text-3xl font-semibold text-about1-900">WHAT WE OFFER</h1>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 justify-center w-[90%] lg:w-[88%] 2xl:w-[80%] mx-auto">
                            <div data-aos="fade-up" data-aos-duration="2000" className="bg-about1-900 relative transition-colors duration-500 ease-in-out delay-200 rounded-md text-white   h-[350px] md:h-96 lg:w-96 2xl:w-[500px] 2xl:h-[500px] p-5 mb-4 shadow-black/50 shadow-md">
                                <div className="w-10 h-10">
                                    <MdArchitecture  className="w-full h-full"/>
                                </div>
                                <h2 className="text-lg font-bold">Civil & Structural Engineering Services</h2>
                                <p data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000" className="text-sm mt-1 md:mt-3">Finite Element Designs Limited Undertakes planning, design, construction supervision and maintenance management of building structures, substation structures, bridges and other major road drainage and water retaining structures.</p>
                                <Link to="/service/engService" className="absolute bottom-6"><button className="text-center text-white rounded-md bg-menu-800 py-2 px-3 cursor-pointer hover:bg-about-700">Learn More</button></Link>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-delay="800" className="bg-about1-900 relative transition-colors duration-500 ease-in-out delay-200 rounded-md text-white h-[350px] md:h-96 lg:w-96 2xl:w-[500px] 2xl:h-[500px] p-5 mb-4 shadow-black/50 shadow-md">
                                <div className="w-10 h-10">
                                    <FaChalkboardTeacher className="w-full h-full" />
                                </div>
                                <h2 className="text-lg font-bold">Structural Engineering Training</h2>
                                <p data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000" className="text-sm mt-3">Finite Element Designs Ltd offers a wide range of trainings in the field of civil and structural engineering.</p>
                                <Link to="/service/training" className="absolute bottom-6"><button className="text-center text-white rounded-md bg-menu-800 py-2 px-3 hover:bg-about-700">Learn More</button></Link>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1000" className="bg-about1-900 relative transition-colors duration-500 ease-in-out delay-200 rounded-md text-white h-[350px] md:h-96 lg:w-96 2xl:w-[500px] 2xl:h-[500px] p-5 mb-4 shadow-black/50 shadow-md">
                                <div className="w-10 h-10">
                                    <FaBuilding  className="h-full w-full"/>
                                </div>
                                <h2 className="text-lg font-bold">Structural Engineering Consultancy</h2>
                                <p data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000" className="text-sm mt-5">Finite Element Designs Limited offers comprehensive structural design consultancy and site supervision services across a wide range of sectors and project types.</p>
                                <Link to="/service/consultancy" className="absolute bottom-6"><button className="text-center text-white rounded-md bg-menu-800 py-2 px-3 hover:bg-about-700">Learn More</button></Link>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1200" className="hidden bg-about1-900 relative transition-colors duration-500 ease-in-out delay-200 rounded-md text-white h-[350px] md:mx-0 md:h-96 lg:w-96 2xl:w-[500px] 2xl:h-[500px] p-5 mb-4 shadow-black/50 shadow-md">
                                <div className="w-10 h-10">
                                    <FaSuitcase  className="w-full h-full"/>
                                </div>
                                <h2 className="text-lg font-bold">Project Management</h2>
                                <p data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000" className="text-sm mt-5">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, type specimen book</p>
                                <Link to="/service/management" className="absolute bottom-6"><button className="text-center text-white rounded-md bg-menu-800 py-2 px-3 hover:bg-about-700">Learn More</button></Link>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1400" className="bg-about1-900 relative transition-colors duration-500 ease-in-out delay-200 rounded-md text-white h-[350px]  md:h-96 lg:w-96 2xl:w-[500px] 2xl:h-[500px] p-5 mb-4 shadow-black/50 shadow-md">
                                <div className="w-10 h-10">
                                    <GiCargoCrane  className="h-full w-full"/>
                                </div>
                                <h2 className="text-lg font-bold">Design-Build</h2>
                                <p data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000" className="text-sm mt-5">Finite Element Designs Limited offers comprehensive design-build services for projects involving concrete and steel structures.</p>
                                <Link to="/service/designbuild" className="absolute bottom-6"><button className="text-center text-white rounded-md bg-menu-800 py-2 px-3 hover:bg-about-700">Learn More</button></Link>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1600" className=" bg-about1-900 relative transition-colors duration-500 ease-in-out delay-200 rounded-md text-white h-[350px] mb-4 lg:w-96 2xl:w-[500px] 2xl:h-[500px] md:h-96 p-5 shadow-black/50 shadow-md">
                                <div className="w-10 h-10">
                                    <GiSteelClaws  className="w-full h-full"/>
                                </div>
                                <h2 className="text-lg font-bold">Steel Fabrication</h2>
                                <p data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000" className="text-sm mt-5">Finite Element Designs Limited is your trusted partner for premium steel fabrication services, delivering precision, quality, and compliance with the highest industry standards and codes.</p>
                                <Link to="/service/steelFab" className="absolute bottom-6"><button className="text-center text-white rounded-md bg-menu-800 py-2 px-3 hover:bg-about-700">Learn More</button></Link>
                            </div>
                            <div data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1800" className="bg-about1-900 relative transition-colors duration-500 ease-in-out delay-200 rounded-md text-white h-[350px] mb-4  md:mx-auto lg:w-96 2xl:w-[500px] 2xl:h-[500px] md:h-96 p-5 shadow-black/50 shadow-md">
                                <div className="w-10 h-10">
                                    <BsPrinterFill  className="w-full h-full"/>
                                </div>
                                <h2 className="text-lg font-bold">Large Format Printing</h2>
                                <p data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000" className="text-sm mt-5">Finite Element Designs Limited presents our cutting-edge Prograft Tx 3000 large format printing services tailored to meet specific needs of architects, engineers and construction professionals.</p>
                                <Link to="/service/largeformat" className="absolute bottom-6"><button className="text-center text-white rounded-md bg-menu-800 py-2 px-3 hover:bg-about-700">Learn More</button></Link>
                            </div>
                        </div>
                    </section>

                    {/* Sample Projects */}
                    <section className="p-3">
                        <div className="text-center">
                            <h3 className="text-[#F4EB13] text-lg">Our Work</h3>
                            <h2 className="text-2xl">Our excellence is evident in our outstanding projects</h2>
                        </div>
                        <div className="w-[90%] my-[50px] mx-auto text-white">
                            <div className="lg:flex gap-3 lg:h-[400px] space-y-[10px] lg:space-y-0">
                                <div className="relative basis-[65%] h-full">
                                    <img src={require('../Assets/SlideShow/IMG20220.jpg')} alt="smaple Projects" className="w-full h-full object-cover" />
                                    <div className="absolute top-0 bg-black bg-opacity-10 w-full h-full p-3">
                                        <h1 className="text-xl font-light">Otuke Residential Home</h1>
                                        <Link to="/project">
                                            <button className="border border-white px-4 py-1 text-xl hover:bg-black hover:border-0 transition-colors duration-500 ease-in-out">Explore</button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="relative basis-[30%] h-full">
                                    <img src={require('../Assets/SlideShow/RichmondSuites.jpg')} alt="smaple Projects" className="w-full h-full object-cover" />
                                    <div className="absolute top-0 bg-black bg-opacity-10 w-full h-full p-3 lg:text-right">
                                        <h1 className="text-xl font-light">Richmond Suites</h1>
                                        <Link to="/project">
                                            <button className="border border-white px-4 py-1 text-xl hover:bg-black hover:border-0 transition-colors duration-500 ease-in-out">Explore</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:flex gap-3 lg:h-[400px] mt-[10px] space-y-[10px] lg:space-y-0">
                                <div className="relative basis-[30%] h-full">
                                    <img src={require('../Assets/SlideShow/HighRise1.JPG')} alt="smaple Projects" className="w-full h-full object-cover" />
                                    <div className="absolute top-0 bg-black bg-opacity-10 w-full h-full p-3">
                                        <h1 className="text-xl font-light">Key West Apartments</h1>
                                        <Link to="/project">
                                            <button className="border border-white px-4 py-1 text-xl hover:bg-black hover:border-0 transition-colors duration-500 ease-in-out">Explore</button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="relative basis-[65%] h-full">
                                    <img src={require('../Assets/SlideShow/IMG_20180.jpg')} alt="smaple Projects" className="w-full h-full object-cover" />
                                    <div className="absolute top-0 bg-black bg-opacity-10 w-full h-full p-3 lg:text-right">
                                        <h1 className="text-xl font-medium">Richmond Suites</h1>
                                        <Link to="/project">
                                            <button className="border border-white px-4 py-1 text-xl hover:bg-black hover:border-0 transition-colors duration-500 ease-in-out">Explore</button>
                                        </Link>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </section>
                </body>
            </html>
        </>
    );
};

export default HomePage;