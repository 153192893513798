import React from "react";
import Slider from "react-slick";


//Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LenanaSiteCarousel = () => {
    const Settings = {
        arrows: true,
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 800,
        autoplaySpeed: 6000,
        slidesToShow: 1,
        slidesToScroll: 1
    }

    return (
        <>
            <div className="w-[90%] md:w-3/4 mx-auto">
                <Slider {...Settings}>
                    <div className="w-full md:w-96 h-[228px] md:h-[400px] lg:h-[600px] mb-10 focus:outline-none">
                        <img src={require('../../Assets/Projects/LENANA ROAD SITE PICS/DSC00471.JPG')} alt="Lenana Road Site Project Image" className="w-full h-full object-cover" />
                    </div>
                    <div className="w-full md:w-96 h-[228px] md:h-[400px] lg:h-[600px] mb-10 focus:outline-none">
                        <img src={require('../../Assets/Projects/LENANA ROAD SITE PICS/DSC00461.JPG')} alt="Lenana Road Site Project Image" className="w-full h-full object-cover" />
                    </div>
                    <div className="w-full md:w-96 h-[228px] md:h-[400px] lg:h-[600px] mb-10 focus:outline-none">
                        <img src={require('../../Assets/Projects/LENANA ROAD SITE PICS/DSC00466.JPG')} alt="Lenana Road Site Project Image" className="w-full h-full object-cover" />
                    </div>
                    <div className="w-full md:w-96 h-[228px] md:h-[400px] lg:h-[600px] mb-10 focus:outline-none">
                        <img src={require('../../Assets/Projects/LENANA ROAD SITE PICS/DSC00462.JPG')} alt="Lenana Road Site Project Image" className="w-full h-full object-cover" />
                    </div>
                </Slider>
            </div>
        </>
    );
}

export default LenanaSiteCarousel;