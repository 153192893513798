import React from "react";
import Slider from "react-slick";

//Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const KileleshwaCarousel = () => {
    const Settings = {
        arrows: true,
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 800,
        autoplaySpeed: 6000,
        slidesToShow: 1,
        slidesToScroll: 1
    }

    return (
        <>
            <div className="w-[90%] md:w-3/4 mx-auto">
                <Slider {...Settings}>
                    <div className="w-96 h-[228px] md:h-[400px] lg:h-[600px] focus:outline-none">
                        <img src={require('../../Assets/Projects/KILELESHWA SAHAN/IMG20230801113254.jpg')} alt="Kileleshwa Project" className="w-full h-full" />
                    </div>
                    <div className="w-96 h-[228px] md:h-[400px] lg:h-[600px] focus:outline-none">
                        <img src={require('../../Assets/Projects/KILELESHWA SAHAN/IMG20230801114223.jpg')} alt="Kileleshwa Project" className="w-full h-full" />
                    </div>
                    <div className="w-96 h-[228px] md:h-[400px] lg:h-[600px] focus:outline-none">
                        <img src={require('../../Assets/Projects/KILELESHWA SAHAN/IMG20230830145514.jpg')} alt="Kileleshwa Project" className="w-full h-full" />
                    </div>
                    <div className="w-96 h-[228px] md:h-[400px] lg:h-[600px] focus:outline-none">
                        <img src={require('../../Assets/Projects/KILELESHWA SAHAN/WhatsApp Image 2023-05-31 at 4.47.00 PM.jpeg')} alt="Kileleshwa Project" className="w-full h-full" />
                    </div>
                    <div className="w-96 h-[228px] md:h-[400px] lg:h-[600px] focus:outline-none">
                        <img src={require('../../Assets/Projects/KILELESHWA SAHAN/IMG20240123163046.jpg')} alt="Kileleshwa Project" className="w-full h-full" />
                    </div>
                    <div className="w-96 h-[228px] md:h-[400px] lg:h-[600px] focus:outline-none">
                        <img src={require('../../Assets/Projects/KILELESHWA SAHAN/WhatsApp Image 2023-05-31 at 4.47.30 PM.jpeg')} alt="Kileleshwa Project" className="w-full h-full" />
                    </div>
                </Slider>
            </div>
        </>
    );
}

export default KileleshwaCarousel;